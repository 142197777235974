(function ($) {
  Drupal.behaviors.esearchVoiceSearchModuleV1 = {
    attach: function () {
      if (!!Drupal.settings.common.voice_search_enabled) {
        const isIphoneChrome = Drupal.behaviors.voiceSearch?.contains(
          navigator.userAgent.toLowerCase(),
          ['iphone', 'crios']
        );

        // We verify this is not Iphone using Chrome and hide the search icon to display mic incon instead.
        if (!isIphoneChrome) {
          if (!Drupal?.settings?.common?.search_modernized_design) {
            $('.js-icon-search', '.js-predictive-search-form').hide();
            $('.js-search-form__submit', '.js-header-voice-search').hide();
          }
        }
      }
    }
  };
})(jQuery);
